import React, { useContext } from 'react';
import TeamName from './teamName';
import DoubleScoreButton from './doubleScoreButton'
import ScoreSection from './scoreSection';
import TeamLogo from './teamLogo';
import ScoreInputs from './scoreInputs';
import ScoreInput from './scoreInput';
import ScoreLine from './scoreLine';
import VALIDATIONS from '../../../core/constants/validations';
import { connect } from 'react-redux';
import useDidUpdateEffect from '../../../core/customHooks/useDidUpdateEffect';
import { getUserInfo } from '../../../state-management/accessors/usersAccessors';
import { getUserId } from '../../../state-management/accessors/lobbyAccessors';
import TypeChecker from '../../../core/helpers/TypeCheker';
import useGoogleAnalytics from '../../../core/customHooks/useGoogleAnalytics';
import { GA_CATEGORIES, GA_ACTIONS } from '../../../core/constants/googleAnalyticsConstants';
import { FieldStateContext } from '../../../contexts';


const GameBody = ({ isUpcoming,
                    isCanceledOrIsPostponed,
                    homeTeam,
                    awayTeam,
                    roundMatchId,
                    predictionsForBet,
                    setPredictionsForBet,
                    setIcon,
                    matchResult,
                    isDoubled,
                    selectedUserId,
                    onPredictionsClick,
                    showPredictionTooltips,
                    user: { id },
}) => {

   const prediction = predictionsForBet[roundMatchId];
   const predictionIsDoubled = predictionsForBet?.isDoubled;


   const inputValues = isUpcoming ? {
     homeTeamScore: prediction?.homeTeamScore ?? '',
     awayTeamScore: prediction?.awayTeamScore ?? ''
   } : matchResult;

   const doubleButtonIsActive = !TypeChecker.isEmptyString(inputValues.homeTeamScore) && !TypeChecker.isEmptyString(inputValues.awayTeamScore) || TypeChecker.isNumber(prediction?.ftts);

   const { fieldIsDisabled } = useContext(FieldStateContext);

   const { emitEvent } = useGoogleAnalytics();

  const isUsersPage = id === selectedUserId;

  useDidUpdateEffect(() => {
    if (!doubleButtonIsActive && predictionsForBet.isDoubled) {
      setPredictionsForBet(prev => ({
        ...prev,
        isDoubled: null
      }))
    }
  }, [doubleButtonIsActive]);

   const handleScoreInputChange = e => {
     const { value, name } = e.target;
     if (VALIDATIONS.NUMBER.test(value)) {
       setPredictionsForBet(prev => ({
         ...prev,
         [roundMatchId]: {
           ...prev[roundMatchId],
           [name]: value
         }
       }));
     } else if (VALIDATIONS.STARTING_WITH_ZEROS.test(value)){
       setPredictionsForBet(prev => ({
         ...prev,
         [roundMatchId]: {
           ...prev[roundMatchId],
           [name]: value.slice(1)
         }
       }))
     }
   };

   const handleDoubleButtonClick = () => {
     onPredictionsClick();
     if (fieldIsDisabled) return;
     setPredictionsForBet(prev => ({
       ...prev,
       isDoubled: predictionIsDoubled === roundMatchId ? null: roundMatchId,
     }))
   };


  const emitScorePredictionToGA = () => {
    emitEvent({
      category: GA_CATEGORIES.PREDICTION,
      action: GA_ACTIONS.SCORE_PREDICTION
    });
  };

  return (
    <div className="top-bottom-inside-game-list">
      {!isUpcoming && !isUsersPage ? <div className="inside-top-bottom-inside-game-list">
        <p className="text-about-games" />
        <div className="score-table-wr">
          <div className="team-name-block">
            <TeamName name={homeTeam.name} shortName={homeTeam.shortName} />
            <div className="team-name-item">
            </div>
            <TeamName name={awayTeam.name} shortName={awayTeam.shortName}  />
          </div>
          <ScoreSection >
            <TeamLogo imageUrl={homeTeam.imageUrl} jerseyUrl={homeTeam.jerseyUrl} title={homeTeam.shortName}/>
            <ScoreInputs showPredictionTooltips={showPredictionTooltips} onClick={() => onPredictionsClick()}>
              <ScoreInput name='homeTeamScore'
                          value={inputValues.homeTeamScore}
                          readOnly={true}
              />
              <ScoreLine />
              <ScoreInput name='awayTeamScore'
                          value={inputValues.awayTeamScore}
                          readOnly={true}
              />
            </ScoreInputs>
            <TeamLogo imageUrl={awayTeam.imageUrl} jerseyUrl={awayTeam.jerseyUrl} title={awayTeam.shortName}/>
          </ScoreSection>
        </div>
      </div> :
      <div className="inside-top-bottom-inside-game-list">
          <p className="text-about-games" />
        <div className="score-table-wr">
          <div className="team-name-block">
            <TeamName name={homeTeam.name} shortName={homeTeam.shortName} />
            <div className="team-name-item">
              <DoubleScoreButton roundMatchId={roundMatchId}
                                 isDoubled={isDoubled}
                                 predictionIsDoubled={predictionIsDoubled}
                                 isActive={doubleButtonIsActive}
                                 isUpcoming={isUpcoming}
                                 isCanceledOrIsPostponed={isCanceledOrIsPostponed}
                                 setIcon={setIcon}
                                 fieldIsDisabled={fieldIsDisabled}
                                 handleDoubleButtonClick={handleDoubleButtonClick}
              />
            </div>

            <TeamName name={awayTeam.name} shortName={awayTeam.shortName} />
          </div>
          <ScoreSection >
            <TeamLogo imageUrl={homeTeam.imageUrl} jerseyUrl={homeTeam.jerseyUrl} title={homeTeam.shortName && homeTeam.shortName.length > 0 ? homeTeam.shortName : homeTeam.name} />
            <ScoreInputs showPredictionTooltips={showPredictionTooltips} onClick={() => onPredictionsClick()}>
              <ScoreInput name='homeTeamScore'
                          value={inputValues.homeTeamScore}
                          handleChange={handleScoreInputChange}
                          readOnly={!isUpcoming || fieldIsDisabled}
              />
              <ScoreLine />
              <ScoreInput name='awayTeamScore'
                          value={inputValues.awayTeamScore}
                          handleChange={handleScoreInputChange}
                          readOnly={!isUpcoming || fieldIsDisabled}
              />
            </ScoreInputs>
            <TeamLogo imageUrl={awayTeam.imageUrl} jerseyUrl={awayTeam.jerseyUrl} title={awayTeam.shortName && awayTeam.shortName.length > 0 ? awayTeam.shortName : awayTeam.name} />
          </ScoreSection>
        </div>
      </div>}
    </div>
  )
};

GameBody.defaultProps={
  currentScore: {
    homeTeamScore: '',
    awayTeamScore: ''
  }
};

const mapStateToProps = state => ({
  user: getUserInfo(state),
  selectedUserId: getUserId(state),
});

const mapDispatchToProps = dispatch => ({
});



export default connect(mapStateToProps, mapDispatchToProps)(GameBody);
