import React, { forwardRef } from 'react';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { combineQuery } from '../../../core/helpers/combineQuery';
import { parseQuery } from '../../../core/helpers/parseQuery';
import { APP_CONSTANTS } from '../../../core/constants/constants';
import { MOBILE_TABS, NAVIGATION_ITEMS } from '../../../core/constants/navigationConstants';
import { ERoundStatuses, PrizeTypes } from '../../../core/constants/enums';
import { TOOLTIP_TARGET_IDS } from '../../../core/constants/userTooltipGuide';

const LeaderBoardItem = forwardRef((props, ref) =>  {

  const handleClick = () => {
    const {
      getLobby,
      getGlobalLobby,
      roundIsGlobal,
      userId,
      roundId,
      selectedUserId,
      status,
      setActiveTab,
      setActiveNavItem,
      isDisabled,
      emitLeaderboardOtherUserUsageToGA,
      } = props;
    if (isDisabled) return;
    const partnerId = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);

    if(roundIsGlobal && userId !== selectedUserId && userId){
      getGlobalLobby({partnerId, otherUserId: userId, skip: 0, take: 30});
      props.history.push(combineQuery({
        ...parseQuery(props.location.search),
        [APP_CONSTANTS.USERID]: userId,
      }));
      setActiveTab(MOBILE_TABS.HOME);
      setActiveNavItem(NAVIGATION_ITEMS.HOME);
      emitLeaderboardOtherUserUsageToGA();
      return
    }
    if (status && userId !== selectedUserId && userId) {
      getLobby({
        partnerId,
        userId,
        roundId
      });
      props.history.push(combineQuery({
        ...parseQuery(props.location.search),
        [APP_CONSTANTS.ROUNDID]: roundId,
        [APP_CONSTANTS.USERID]: userId
      }));
      setActiveTab(MOBILE_TABS.HOME);
      setActiveNavItem(NAVIGATION_ITEMS.HOME);
      emitLeaderboardOtherUserUsageToGA();
    }
  };

    const {
      userId,
      selectedUserId,
      active,
      nickname,
      activeUserNickname,
      rank,
      point,
      prize,
      status,
      isDisabled,
      displayID,
      currencySign,
      additionalPrize,
      prizeType
    } = props;
    const selected = userId === selectedUserId;
    const nick = displayID ? userId : active ? activeUserNickname : nickname;

    return (
      <div className={`leaderboard-row-wr ${active ? 'active': ''}`} data-tooltip={TOOLTIP_TARGET_IDS.TOTAL_EARNED_POINTS}>
        <div className={`info-row item ${selected ? 'selected' : '' } ${status? '': 'upcoming'} ${isDisabled ? 'tooltip-disabled' : ''}`}
            onClick={handleClick} ref={ref}>
          <div className="info-cell">{rank}</div>
          <div className="info-cell" title={nick}>{nick}</div>
          <div className="info-cell">{point}</div>
          {status === ERoundStatuses.FINISHED ?
            <div className="info-cell">
              {prize} {currencySign} {additionalPrize && ` + ${additionalPrize}  ${prizeType && PrizeTypes[prizeType]}` }
            </div> :
            <div className="info-cell" /> }
        </div>
      </div>
    )});

LeaderBoardItem.defaultProps = {
  prize: 0,
};


export default LeaderBoardItem;
