import React from 'react';
import useTranslation from '../../../core/customHooks/useTranslation';
import { EMoneyTypes, PrizeTypes } from '../../../core/constants/enums';
import { PRIZE_TYPES } from '../../../core/constants/constants';


const PrizeItem =({ places, winning, prizeType, additionalPrize, currencySign, moneyType }) => {

  const translate = useTranslation();

  return (
    <div className="prize-row item">
      <div className="prize-cell">{places}</div>
      <div className="prize-cell">{winning} {moneyType !== EMoneyTypes.freeSpin && currencySign} {additionalPrize && ` + ${additionalPrize} ${prizeType && translate(PrizeTypes[prizeType])}` }</div>
      {moneyType !== EMoneyTypes.real &&
      <div className="prize-cell" title={translate(PRIZE_TYPES[moneyType])}>{translate(PRIZE_TYPES[moneyType])}</div>
      }
    </div>
  );
};

export default PrizeItem;
