import Enum from '../helpers/Enum';

export const EGameStatuses = Enum('UPCOMING','LIVE', 'FINISHED', 'POSTPONED', 'PRELIVE', 'CANCELED', )();
export const ERoundStatuses = Enum('UPCOMING', "STARTED", "FINISHED", 'BLOCKED', 'CANCELED', 'VERIFICATION')();
export const ERoundCancelMethods = Enum('None', 'Manual', 'Automatically')();
export const EFirstTeamToScore = Enum('goalsDraw', 'homeTeam','awayTeam')();
export const EMatches = Enum('homeTeamMatches', 'h2HMatches', 'awayTeamMatches')(1);
export const EPredictionStatuses = Enum('notCorrect', 'partlyCorrect', 'Correct')();
export const EMoneyTypes = Enum('real', 'freeBet', 'freeSpin')();
export const PrizeTypes = Enum('prize', 'freeBet', 'freeSpin')();
export const RelativeOrAbsolute = Enum('Absolute ', 'Relative')(1);
export const UrlSource = Enum('Predictor', 'Statistics')(1);
export const SelectionTypes = Enum('None', 'CorrectScore', 'AsianHandicap', 'MatchResult', 'TotalGoals', 'FirstTeamToScore')();
export const PredictionMode = Enum('None', 'ScoreAndFtts', 'Markets', 'Both')();
export const SelectionSubType = Enum('None', 'HomeScore', 'AwayScore')();
export const PrizeFundTypes = Enum('None', 'GuaranteedAmount', 'RakeAmount', 'IncreasingGuaranteed')();

