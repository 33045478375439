import React,  { useEffect } from 'react';
import { connect } from 'react-redux';
import { getLobby, getRoundInfo } from '../../../state-management/accessors/lobbyAccessors';
import PartnerModule from '../../../core/moduls/PartnerModul';
import { getUserInfo } from '../../../state-management/accessors/usersAccessors';
import { getCurrencySign } from '../../../core/helpers/getCurrency';
import { APP_CONSTANTS } from '../../../core/constants/constants';
import { cntrlGetCurrentDistributions } from '../../../state-management/actions/prizeActions';
import PrizeItem from './prizeItem';
import { cntrlGetDistribution, rxGetDistributionDone } from '../../../state-management/actions/roundActions';
import { getDistribution } from '../../../state-management/accessors/roundAccessors';
import useTranslation from '../../../core/customHooks/useTranslation';
import t from '../../../core/staticData/translation';
import { EMoneyTypes, PrizeFundTypes } from '../../../core/constants/enums';
import { PRIZE_TYPES } from '../../../core/constants/constants';
import { getHourAndMinute, getStartAndEndDate } from '../../../core/helpers/dateHelpers';
import PRIZE_FUND_TYPE_INFO from '../../../core/constants/prizeFundTypeIcons';
import avatar1 from '../../../images/promo.png';

const PrizeSection = ({ roundInfo: { id: roundId, minJoinCount, startDate, endDate, entries, rake, entryFee, prizeFundType },
                        getDistribution,
                        getGlobalDistribution,
                        distribution,
                        resetDistribution,
                        roundIsGlobal,
                        user: { id: userId }
                      }) => {

  const translate = useTranslation();
  const partnerID = PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID);

  useEffect(() => {
    if(roundIsGlobal){
      getGlobalDistribution()
        .then(res => res && resetDistribution());
    } else if (roundId){
      getDistribution(roundId)
        .then(res => res && resetDistribution());
    }

  }, [roundId, userId, roundIsGlobal]);

  const currencySign = getCurrencySign();
  const { moneyType } = distribution;
  const showRake = prizeFundType !== PrizeFundTypes.GuaranteedAmount && moneyType !== EMoneyTypes.freeBet && moneyType !== EMoneyTypes.freeSpin;

  return (
    <div className="weekly-container">
      {!!distribution.rows.length &&
      <div className="inside-weekly-c-wr">
          <div className="top-of-inside-weekly-c-wr">
            {
              // TODO: its a temporary change to show promo banner;
              +partnerID === 18749451 ? <div className='weekly-promo-wr'>
                <p>{translate(t.CORRECT_SCORES)}</p>
                <img className='weekly-promo-img' src={avatar1} alt="" />
              </div> : null
            }
              <div className="info-section-holder">
                  <div className="freebet-wr" >
                      {moneyType !== EMoneyTypes.freeSpin &&
                      <span className="freebet-wr-spn">{distribution.prizeFund} {currencySign}</span>}
                      <span className="freebet-wr-spn" title={translate(PRIZE_TYPES[moneyType])}>{translate(PRIZE_TYPES[moneyType])}</span>
                      <span
                        title={translate(PRIZE_FUND_TYPE_INFO[prizeFundType]?.title)}
                        className={`prize-fund-type-icon ${PRIZE_FUND_TYPE_INFO[prizeFundType]?.icon}`}
                      />
                  </div>
                  <div className="info-section">
                      <div className="info-section-row">
                          <p className="info-section-row-name">
                            <span title={translate(t.DATE)}>{translate(t.DATE)}</span>
                          </p>
                          <p className="info-section-row-data" >{getStartAndEndDate(startDate, endDate)}</p>
                      </div>
                      <div className="info-section-row">
                          <p className="info-section-row-name">
                            <span title={translate(t.STARTS_IN)}>{translate(t.STARTS_IN)}</span>
                          </p>
                          <p className="info-section-row-data" >{getHourAndMinute(startDate)}</p>
                      </div>

                      {
                          !roundIsGlobal &&
                          <div className="info-section-row">
                              <p className="info-section-row-name">
                                <span title={translate(t.MIN_JOIN)}>{translate(t.MIN_JOIN)}</span>
                              </p>
                              <p className="info-section-row-data">{minJoinCount}</p>
                          </div>
                      }
                      <div className="info-section-row">
                          <p className="info-section-row-name">
                            <span title={translate(t.JOINED)}>{translate(t.JOINED)}</span>
                          </p>
                          <p className="info-section-row-data">{entries}</p>
                      </div>

                      {
                          !roundIsGlobal &&
                            <>
                                <div className="info-section-row">
                                    <p className="info-section-row-name">
                                      <span title={translate(t.ENTRY_FEE)}>{translate(t.ENTRY_FEE)}</span>
                                    </p>
                                    <p className="info-section-row-data">{entryFee ? `${entryFee} ${currencySign}` : translate(t.FREE)} </p>
                                </div>
                                {
                                  showRake &&
                                  <div className="info-section-row">
                                      <p className="info-section-row-name">
                                        <span title={translate(t.RAKE)}>{translate(t.RAKE)}</span>
                                      </p>
                                      <p className="info-section-row-data">{rake}%</p>
                                  </div>
                                }
                            </>
                      }
                  </div>
              </div>
              <div className="prize-row title">
                  <div className="prize-cell" title={translate(t.PLACES)}>{translate(t.PLACES)}</div>
                  <div className="prize-cell"/>
                  <div className="prize-cell" title={translate(t.WINNING)}>{translate(t.WINNING)}</div>
              </div>
          </div>
        <div className="bottom-of-inside-weekly-c-wr">
          <div className="scroll-wr" >
            <div className="weekly-wr" >
              {distribution.rows.map(item => {
                const { places, winning, prizeType ,additionalPrize } = item;
                return (
                  <PrizeItem
                    key={item.places}
                    places={places}
                    winning={winning}
                    prizeType={prizeType}
                    additionalPrize={additionalPrize}
                    currencySign={currencySign}
                    moneyType={moneyType}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>}
    </div>
  );
};


const mapStateToProps = state => ({
  roundInfo: getRoundInfo(state),
  distribution: getDistribution(state),
  user: getUserInfo(state),
  roundIsGlobal: !!getLobby(state).data.globalRound
});

const mapDispatchToProps = dispatch => ({
  getDistribution: id => dispatch(cntrlGetDistribution({id: id, partnerId: PartnerModule.getConfigItem(APP_CONSTANTS.PARTNER_ID)})),
  resetDistribution: () => dispatch(rxGetDistributionDone({rows: []})),
  getGlobalDistribution: () => dispatch(cntrlGetCurrentDistributions()),
});



export default connect(mapStateToProps, mapDispatchToProps)(PrizeSection);
